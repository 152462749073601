import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Polygon } from "react-google-maps";
const flightPlanCoordinates = [
    { lat: -34.397, lng: 150.644 },
    { lat: 21.291, lng: -157.821 },
  ];

const GoogleMaptwo = withScriptjs(withGoogleMap((props) =>

  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: parseFloat(props.robotLocation.lat), lng: parseFloat(props.robotLocation.lon) }}
    MapTypeControlOptions={false}
    >

     {props.isMarkerShown && <><Marker className="google-map-marker" position={{ lat: parseFloat(props.robotLocation.lat), lng: parseFloat(props.robotLocation.lon) }} 
        icon={{
           url: '/assets/images/robot-icon-main.svg',
          scaledSize: new window.google.maps.Size(25, 25),
        }}/>
        <Marker className="google-map-marker" position={{ lat: props.orderDropLocation.lat, lng: props.orderDropLocation.lon }} 
        icon={{
           url: '/assets/images/robot_dropOff_main.svg',
          scaledSize: new window.google.maps.Size(25, 25),
        }}/>
        <Marker className="google-map-marker" position={{ lat: props.orderPickupLocation.lat, lng: props.orderPickupLocation.lon }} 
        icon={{
           url: '/assets/images/PickUpLocation-icon.svg',
          scaledSize: new window.google.maps.Size(25, 25),
        }}/>
        </>}
 
  </GoogleMap>
))


export default GoogleMaptwo