import React, { useEffect, useState } from 'react'
import Header from './Header'
import $ from 'jquery'
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from 'react-router-dom';

import { emphasize } from '@mui/material';
import { getdeliverytime, getOrderDetails, OrderQrcode, sendmsglocation } from '../API';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OTPInput, { ResendOTP } from "otp-input-react";
import GoogleMapMain from './GoogleMapMain';

function Congratulations() {
  
  const [remaintime, setremaintime]=useState()
  const [open, setOpen] = React.useState(false);
  const [opentwo, setOpentwo] = React.useState(false);
  const [orderDetails, setorderDetails] =useState()
  const [otp, setOtp] = useState(orderDetails?.pin); 

  const [searchParams, setSearchParams] = useSearchParams()
  const orderID= searchParams.get("orderId")
  var rString=''
  const orderData =useLocation()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor:'#ffffff',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px',
    paddingTop: '11px',
    paddingBottom:'0px',
    border:'none',
    paddingRight:'0px',
    paddingLeft:'0px',
    zIndex:'999999999999999999999999'
  };
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  }
  const handleClosetwo = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpentwo(false);
  }

const handleOpen = () => setOpen(true);

const handleopentwo = () => setOpentwo(true);
  const [newstring, setnewstring] =useState()
   useEffect(()=>{
  
    // generateBarCode(orderData.state.newstring)
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hrs= today.getHours(); // => 9
    let mins=today.getMinutes(); // =>  30
    var ampm = hrs >= 12 ? 'pm' : 'am';
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12
    if (dd < 10){
      dd = '0' + dd;
    } 
    if (mm < 10){
      mm = '0' + mm;
    } 
    if(hrs < 10){
      hrs ='0'+hrs
    }
    if(mins < 10){
      mins ='0'+mins
    }
    const formattedToday = mm + '-' + dd + '-' + yyyy + '\xa0\xa0\xa0\xa0\xa0'+hrs +":" + mins +'\xa0\xa0'+ ampm;
    document.getElementById('DATE').innerHTML = formattedToday;
   setInterval(()=>{
    getdeliverytime(orderData.state.newstring).then((res)=>{
      // console.log('Response timer',res)
      setremaintime(res.data.data[0].deliveryTime)
     }).catch((err)=>{
         console.log(err)
     })
   }, 4000)  
  
   },[])
   let mapheight='327px'
   
  useEffect(()=>{
     if(document.getElementById('Estimated_time')){
      var offsetHeight = document.getElementById('Estimated_time').offsetHeight;
     }
    
    console.log('mapheight',mapheight)
    setInterval(()=>{
      getOrderDetails(orderID)
      .then((res)=>{
       console.log('Order Details response',res)
       setorderDetails(res.data.data)
       setOtp(res.data?.data?.pin)
      })
      .catch((err)=>{
        console.log('Error ', err)
      })
    }, 3000)
    
    mapheight= mapheight-offsetHeight +'px'
    // document.getElementById('mapDiv').style.height=mapheight
  },[mapheight,otp])


    function generateBarCode(qrcode)
    {
        var url = 'https://api.qrserver.com/v1/create-qr-code/?data=' + qrcode + '&amp;size=50x50';
        $('#barcode').attr('src', url);
        // OrderQrcode(orderId,url).then((res)=>{
        //      console.log('QRlInk reposne',res)
        // }).catch((err)=>{
        //   console.log(err)
        // })
        return rString
    }
 
    const { t } = useTranslation() 
  //   function startTimer(duration, display) {
  //     var timer = duration, minutes, seconds;
  //     setInterval(function () {
  //         minutes = parseInt(timer / 60, 10)
  //         seconds = parseInt(timer % 60, 10);
  
  //         minutes = minutes < 10 ? "0" + minutes : minutes;
  //         seconds = seconds < 10 ? "0" + seconds : seconds;
  
  //         display.textContent = minutes + ":" + seconds;
  
  //         if (--timer < 0) {
  //             timer = duration;
  //         }
  //     }, 1000);
  // }
  
  // window.onload = function () {
  //     var fiveMinutes = 60 * 20,
  //         display = document.querySelector('#time');
  //     startTimer(fiveMinutes, display);
  // };
    return (
    
     <>
      <div className='MainWhole-wrapper-container'>  
      {/* {console.log('orderIdorderId',orderData)} */}
     <Header open={open} opentwo={opentwo}/>
    <div className='Congratulation-Main-wrapper'>
       <div className='Congratulation-content-div-one-wrapper'>
             <div className='Congratulation-content-div-one-wrapper-inner-one'> 
               <img src="/assets/images/noun-info-2096294.png"/>    
            </div>
           <div className='Congratulation-content-div-one-wrapper-inner-two'>
                <p> {orderDetails?.orderStatus} </p> 
           </div>
       </div>
       <div className='Congratulation-page-order-wrapper-below-QR'> 
       <div> 
            <h5 >RobotID:</h5>
             {orderDetails?.robotId}  
            {/* <p> {orderData.state.newstring} </p> */}
        </div> 
        <div> 
            <h5 >OrderID:</h5>
             {orderID}  
            {/* <p> {orderData.state.newstring} </p> */}
        </div>    
        <div> 
            <h5 >Date:</h5>
            <p id="DATE">  </p>
            {/* {orderDetails?.createdAt} */}
        </div>
     {console.log('mapheight',mapheight)}
     </div> 
    <div className="Congratulations_page_QRcode_pin_wrapper">
         <h3> Get your item with the below options</h3>  
          
          <div className='Congratulations_page_QRcode_pin_inner'> 
            {orderDetails?.deliveryTime <= 0 ? <><button onClick={handleOpen}> Scan QR Code</button>
            <p> OR</p>
            <button onClick={handleopentwo}> Enter PIN</button></>:<>
            <button onClick={handleOpen} className="Congratulations_page_QRcode_disabled_button" disabled> Scan QR Code</button>
            <p> OR</p>
            <button onClick={handleopentwo} className="Congratulations_page_QRcode_disabled_button" disabled> Enter PIN</button>
            </>} 
            
         </div> 
    </div> 
    
      <Modal    open={open}
                // BackdropProps={false}
                onHide={handleClose}
                // onClose={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">  
      <Box sx={style}>
          <div className='QR-Code-wrapper-whole'> 
            <div className='QR-Code-wrapper-whole-inner-one'>
              <h3> Scan QR Code</h3>
              <div className='Sorry_div_wrapper_inner_div' style={{paddingBottom:'0px'}} onClick={handleClose}>
                 <HighlightOffIcon style={{fontSize:'35px', margin:'5px'}}/>
                 <p style={{fontSize:'17px'}}>Close</p> 
             </div> 
            </div>       
            <p style={{marginBottom:'12px'}}>  Show the QR code to the camera</p>
            <div className='QR-Code-main-inner'> 
            {generateBarCode(orderDetails?.qrcode)}
             <img id='barcode' 
                src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100" 
                alt="" 
                title="Congratualtions" 
                width="500" 
                height="500"
                style={{objectFit:'cover'}} />
            </div>      
        </div>
        </Box>
        </Modal> 
        <Modal     open={opentwo}
                // BackdropProps={false}
                onHide={handleClosetwo}
                // onClose={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">  
      <Box sx={style}>
          <div className='QR-Code-wrapper-whole'> 
            <div className='QR-Code-wrapper-whole-inner-one'>
              <h3> Your PIN</h3>
              <div className='Sorry_div_wrapper_inner_div' style={{paddingBottom:'0px'}} onClick={handleClosetwo}>
                 <HighlightOffIcon style={{fontSize:'35px', margin:'5px'}}/>
                 <p style={{fontSize:'17px'}}>Close</p> 
             </div> 
            </div>       
            <p style={{marginBottom:'12px'}}>  Enter the PIN on the Top Screen</p>
            <div className='QR-Code-main-inner'> 
               <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false}  className="Cart_page_otp_input" />
            </div>
        </div>
        </Box>
        </Modal>
        <div className="Congralutions_page_map_div" style={{ height : mapheight, width:'100%' }} id="mapDiv"> 
        {orderDetails?.orderDropLocation.lat ===undefined || orderDetails?.orderDropLocation.lon ===undefined || orderDetails?.orderPickupLocation.lat ===undefined || orderDetails?.orderPickupLocation.lon ===undefined ? null :
         <GoogleMapMain
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRQzEYlLWkb-swa-hIj5YW9F1Kz-ixg0M&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: mapheight, width:'100%' }} />}
            containerElement={<div style={{ height: mapheight, width:'100%' }} />}
            mapElement={<div style={{ height: mapheight, width:'100%' }} />}
            orderDropLocation={orderDetails?.orderDropLocation}
            orderPickupLocation={orderDetails?.orderPickupLocation}
            robotLocation={orderDetails?.robotLocation}
          />
        }
       </div>
       {orderDetails?.deliveryTime > 0 ? <>
        <div className='Congratulations_page_estimated_time_div' id="Estimated_time"> 
          <p> Your Estimated Time of Delivery  </p> 
          <h3> {((orderDetails?.deliveryTime)/60).toFixed(0)} min</h3>
        </div> </>:null}
    </div>
   </div> 

    </>
  )
}

export default Congratulations