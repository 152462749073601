import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import '../src/CSS/custom.css'
import Congratulations from './components/Congratulations';


function App() {
  
  return (
    <div className="App">
       <Routes>
          <Route path="/" element={<Congratulations/>}/>  
       </Routes>
    </div>
  );
}

export default App;
